<template>
  <div class="product-details">
    <div v-editable="blok">
            <h1
              class="mb-8 text-3xl font-semibold tracking-wider text-blue-900">
              {{ blok.name }} 
            </h1>
            <div class="mt-3">
              <h2 class="sr-only">Product information</h2>
              <div class="flex gap-4">
              <div class="text-3xl text-blue-800 text-opacity-100">
                Price: {{ blok.price }}
                {{ blok.currency }}
              </div>
              <div v-show="blok.originalPrice" class="text-2xl justify-self-end self-end align-buttom
              line-through
              align-middle text-cyan-700 text-opacity-75">
              
                 {{ blok.originalPrice }}
                {{ blok.currency }}
              </div>
              </div>
            </div>

            <!-- Reviews -->
            <div class="mt-3">
              <h3 class="sr-only">Reviews</h3>
              <div class="flex items-center">
                <div class="flex items-center">
                  <StarIcon
                    v-for="rating in [0, 1, 2, 3, 4]"
                    :key="rating"
                    :class="[
                      blok.rating > rating
                        ? 'text-yellow-500'
                        : 'text-gray-300',
                      'h-5 w-5 flex-shrink-0',
                    ]"
                    aria-hidden="true"
                  />
                </div>
                <p class="sr-only">
                  {{ blok.rating }} out of 5 stars
                </p>
              </div>
            </div>

            <div class="mt-6">
              <h3 class="sr-only">Description</h3>
              <div class="text-base  space-y-6">
                <p class="text-blue-900 leading-relaxed tracking-wide text-xl 
                text-right	">
                  {{ blok.description }}
                </p>
                <div class="border-r-4 border-gray-300 border-l-2">
                  <dir class="mr-2 text-blue-900 tracking-normal  text-xl">
                    <div class="text-right">
                  {{blok.generalInfo[0].description}}
                    </div>
                  <br>
                  <div class="text-left -mt-8 -ml-8">
                  {{blok.generalInfo[0].mail}}
                  </div>
                  </dir>
                  <!-- <generalInfo :blok="blok.body"></generalInfo> -->
                </div>
              </div>
        <div class="bubbles">
            <img src="../assets/bubble.png" alt="">
            <img src="../assets/bubble.png" alt="">
            <img src="../assets/bubble.png" alt="">
            <img src="../assets/bubble.png" alt="">
            <img src="../assets/bubble.png" alt="">
            <img src="../assets/bubble.png" alt="">
        </div>

              <productFeatures :blok="blok.features"></productFeatures> 
            </div>
    </div>

  </div>
</template>

<script>
  import { StarIcon } from '@heroicons/vue/solid';
import { mapActions} from 'vuex';

  export default {
    props: ['blok'],
    mounted() {
      console.log("this.blok.currency",this.blok.currency)
      this.setPrice(this.blok.price)
      this.setCurrency(this.blok.currency)
      this.setCurrencySign(this.blok.currencySign)
      this.setColors(this.blok.colors)
      this.setCouponCode(this.blok.couponCode)
      this.setCouponDiscount(this.blok.couponDiscount)
      this.setCouponMaxDate(this.blok.couponMaxDate)
      this.setOriginalPrice(this.blok.originalPrice)
    },
    components: { StarIcon},
    methods: {
      ...mapActions(['setPrice','setOriginalPrice','setCurrency','setCurrencySign','setColors',
      'setCouponCode','setCouponDiscount','setCouponMaxDate'
        ]),
    }
  }
</script>

<style scoped>
/* .product-view-details {
    height: 100vh;
} */
.bubbles {
  /* width: 30%; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: -70px;
}
.bubbles img {
  width: 50px;
  animation: bubbles 7s linear infinite;
  -webkit-animation: bubbles 7s linear infinite;
}
@keyframes bubbles {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(-80vh);
    opacity: 0;
  }
}

.bubbles img:nth-child(1) {
  animation-delay: 1s;
  width: 25px;
}
.bubbles img:nth-child(2) {
  animation-delay: 0s;
}
.bubbles img:nth-child(3) {
  animation-delay: 2s;
  width: 25px;
}
.bubbles img:nth-child(4) {
  animation-delay: 3.5s;
}
.bubbles img:nth-child(5) {
  animation-delay: 2.5s;
}
.bubbles img:nth-child(6) {
  animation-delay: 5s;
  width: 20px;
}
.bubbles img:nth-child(7) {
  animation-delay: 4s;
  width: 35px;
}
</style>
