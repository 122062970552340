<template>
  <div v-editable="blok">
    <div class="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
      <p class="text-md"></p>
      <section aria-labelledby="details-heading" class="mt-12">
        <h2 id="details-heading" class="sr-only">Additional details</h2>

        <div class="border-t divide-y divide-gray-200">
          <Disclosure as="div" v-slot="{ open }">
            <h3>
              <DisclosureButton
                class="group relative w-full py-6 flex justify-between items-center text-left"
              >
                <span
                class="text-xl"
                  :class="[
                    open ? 'text-indigo-800' : 'text-indigo-700',
                    'text-base font-medium',
                  ]"
                >
                  <!-- {{ blok.name }} -->
                  Features
                </span>
                <span class="ml-6 flex items-center">
                  <PlusSmIcon
                    v-if="!open"
                    class="block h-6 w-6 text-blue-800 group-hover:text-indigo-800"
                    aria-hidden="true"
                  />
                  <MinusSmIcon
                    v-else
                    class="block h-6 w-6 text-blue-800 group-hover:text-blue-800"
                    aria-hidden="true"
                  />
                </span>
              </DisclosureButton>
            </h3>
            <DisclosurePanel as="div" class="pb-6 prose prose-sm">
              <ul role="list">
                <div class="flex " v-for="item in blok" :key="item.id">
                  <CheckIcon
                    class="h-4 w-4 mt-1 text-base text-left text-blue-800"
                  ></CheckIcon>
                  <span class="ml-2"> {{ item.description }} </span>
                </div>
              </ul>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  } from '@headlessui/vue';
  import { CheckIcon } from '@heroicons/vue/solid';
  import { MinusSmIcon, PlusSmIcon } from '@heroicons/vue/outline';

  export default {
    props: ['blok'],
    components: {
      Disclosure,
      DisclosureButton,
      DisclosurePanel,
      MinusSmIcon,
      PlusSmIcon,
      CheckIcon,
    },
  };
</script>

<style></style>
