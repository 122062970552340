import useVuelidate from '@vuelidate/core';
import {
  required,
  email,
  minLength,
  minValue,
  maxValue,
} from '@vuelidate/validators';
// import { required, email, minLength, maxLength, sameAs } from '@vuelidate/validators'
import emailjs from 'emailjs-com';
import PaypalButton from '@/components/PaypalButton/PaypalButton.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  components: {
    PaypalButton,
  },

  updated() {
    if (!this.updatedHook && this.$store.state.order.status === 'COMPLETED') {
      this.form.firstName = this.firstNameField;

      this.form.lastName = this.lastNameField;
      this.form.email = this.emailField;

      this.form.color = this.order.color;
      this.form.quantity = this.order.quantity;
      this.form.country = this.countryField;
      this.form.city = this.cityField;
      this.form.address = this.addressField;
      this.form.zipCode = this.zipCodeField;

      this.v$.$touch();

      this.updatedHook = true;

      console.log('updated(): ', this.v$.form.$anyDirty);
    }
  },
  computed: {
    ...mapGetters([
      'order',
      'paidFor',
      'quantity',
      'totalAmount',
      'price',
      'currency',
    ]),
    paidForFlag: {
      get() {
        return this.paidFor;
      },
      set(value) {
        this.setOrderPaidFor(value);
      },
    },
    totalAmountField: {
      get() {
        return this.totalAmount;
      },
      set() {
        this.setOrderTotalAmount(
          // Fix?:
          this.price * this.order.quantity
        );
      },
    },
    //
    firstNameField: {
      get() {
        return this.order.firstName;
      },
      set(value) {
        this.setOrderFirstName(value);
      },
    },
    //
    lastNameField: {
      get() {
        return this.order.lastName;
      },
      set(value) {
        this.setOrderLastName(value);
      },
    },
    //
    emailField: {
      get() {
        return this.order.email;
      },
      set(value) {
        this.setOrderEmail(value);
      },
    },
    //
    countryField: {
      get() {
        if (this.order.country === '') {
          return 'Israel';
        }
        return this.order.country;
      },
      set(value) {
        this.setOrderCountry(value);
      },
    },
    //
    cityField: {
      get() {
        return this.order.city;
      },
      set(value) {
        this.setOrderCity(value);
      },
    },
    //
    addressField: {
      get() {
        return this.order.address;
      },
      set(value) {
        this.setOrderAddress(value);
      },
    },
    //
    zipCodeField: {
      get() {
        return this.order.zipCode;
      },
      set(value) {
        this.setOrderZipCode(value);
      },
    },
    //
    quantityField: {
      get() {
        return this.quantity;
      },
      set(value) {
        this.setOrderQuantity(value);
        this.setOrderTotalAmount(this.price * this.quantityField);
      },
    },
  },
  data() {
    return {
      updatedHook: false,
      uiState: 'submit not clicked',
      uiMessage: 'Now, Just verify the shipping details and we are done!',
      contactMail: 'moodifiers.com@gmail.com',
      errors: false,
      empty: true,
      invalid: false,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        //
        color: '',
        quantity: 0,
        //
        country: '',
        city: '',
        address: '',
        zipCode: '',
      },
    };
  },
  methods: {
    ...mapActions([
      'setOrderPaidFor',
      'setOrderStatus',
      'setOrderId',
      'setOrderTotalAmount',
      'setOrderFirstName',
      'setOrderLastName',
      'setOrderEmail',
      'setOrderCountry',
      'setOrderCity',
      'setOrderAddress',
      'setOrderZipCode',
      'setOrderQuantity',
    ]),
    OrderFulfill(data) {
      console.log('inside OrderFulfill :', data.order);
      console.log('inside OrderFulfill :', data.paidFor);
      this.setOrderPaidFor(data.paidFor);
      this.setOrderStatus(data.order.status);
      this.setOrderId(data.order.id);
    },

    newOrder() {
      this.paidForFlag = false;
      this.quantityField = 1;

      this.cleanForm();
    },
    cleanForm() {
      this.uiState = 'submit not clicked';

      this.errors = false;
      this.invalid = false;
      //
      this.form.firstName = '';
      this.form.lastName = '';
      this.form.email = '';
      this.form.country = '';
      this.form.city = '';
      this.form.address = '';
      this.form.zipCode = '';
      //
      this.v$.$reset();
    },
    submitForm(e) {
      console.log('submitForm(): ', this.v$.form.$anyDirty);

      this.empty = !this.v$.form.$anyDirty;
      this.errors = this.v$.form.$errors;
      this.invalid = this.v$.$invalid;
      this.uiState = 'submit clicked';
      console.log('this.empty', this.empty);
      console.log('this.errors', this.errors);
      console.log('this.invalid', this.invalid);

      if (this.errors.length === 0 && this.empty === false && !this.invalid) {
        console.log('successful form!');
        this.firstNameField = this.form.firstName;
        this.lastNameField = this.form.lastName;
        this.emailField = this.form.email;
        this.colorField = this.form.color;
        this.quantityField = this.form.quantity;
        this.countryField = this.form.country;
        this.cityField = this.form.city;
        this.addressField = this.form.address;
        this.zipCodeField = this.form.zipCode;

        this.sendEmail(e);
      }
    },
    sendEmail() {
      try {
        emailjs
          .sendForm(
            process.env.VUE_APP_EMAILJS_SERVICE_ID,
            process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
            // e.target,
            this.$refs.form,
            process.env.VUE_APP_EMAILJS_USER_ID
          )
          .then(
            (response) => {
              console.log('SUCCESS!', response.status, response.text);
              this.uiState = 'form submitted';
            },
            (error) => {
              console.log('mail sent error: ', error);
              this.uiMessage = `There is an error sending the mail. Please contact ${this.contactMail} so we can handle your order.
              Sorry for the inconvenience`;
            }
          );
      } catch (error) {
        console.log({ error });
      }
    },
  },
  validations() {
    return {
      form: {
        firstName: {
          required,
          minLength: minLength(2),
        },
        lastName: {
          required,
          minLength: minLength(2),
        },
        email: {
          required,
          email,
        },
        color: { required },
        quantity: { required, minValue: minValue(1), maxValue: maxValue(10) },
        country: { required, minLength: minLength(2) },
        city: { required, minLength: minLength(2) },
        address: { required },
        zipCode: { required, minLength: minLength(5) },
      },
    };
  },
};
