<div>
  <div v-if="!paidForFlag">
    <div class="grid grid-cols-6 mt-4">
      <div class="col-span-3">
        <label
          for="quantity"
          class="block text-xl font-medium text-gray-700 sm:mt-px sm:pt-2"
          >Quantity</label
        >
        <input
          type="number"
          min="1"
          max="10"
          step="1"
          name="quantity"
          id="quantity"
          v-model="quantityField"
          class="text-xl font-normal rounded-md"
        />
      </div>
      <div class="col-span-3">
        <label
          for="color"
          class="block text-xl font-medium text-gray-700 sm:mt-px sm:pt-2 "
          >Color
        </label>
        <select id="color" name="color" v-model="colorField" class="text-xl font-normal rounded-md">
          <option v-for="color in colors" :key="color" >{{color}}</option>
        </select>
      </div>
    </div>

    <div v-if="!couponApplied">
      <Coupon @couponApplied="couponFound"> </Coupon>
      
    </div>
    <div v-else class=" m-4 p-3 text-green-600 text-center font-sans text-xl border-2 border-dotted ">
      <p>Your coupon code was successfully applied! You save {{orderCouponDiscount}}{{currencySign}} per purchased unit</p>
    </div>
    <div class="shadow-sm text-3xl rounded-md sm:p-1 md:p-4 m-4 text-center text-blue-800">
      Total Price: {{ totalAmount }} {{ currencySign }}
      </div>
  </div>

  <div v-if="paidForFlag">
    <h1>Thanks for ordering your Moodifier!</h1>
  </div>

  <div ref="paypal"></div>
</div>
