import { createStore } from 'vuex'

export default createStore({
  state: {
    order: {
      id: '',
      status: '',
      paidFor: false,
      quantity: 1,
      color: 'Gray',
      totalAmount: 0,
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      city: '',
      address: '',
      zipCode: '',
      orderCouponCode: '',
      orderCouponDiscount: 0,
    },

    products: {
      moodifier: {
        price: 0,
        originalPrice: 0,
        currency: '',
        currencySign: '',
        colors: [],
        couponCode: '',
        couponDiscount: 0,
        couponMaxDate: '',
      },
    },
  },

  getters: {
    price: (state) => {
      return state.products.moodifier.price;
    },

    originalPrice: (state) => {
      return state.products.moodifier.originalPrice;
    },

    currency: (state) => {
      return state.products.moodifier.currency;
    },
    currencySign: (state) => {
      return state.products.moodifier.currencySign;
    },
    colors: (state) => {
      return state.products.moodifier.colors;
    },
    // Coupon properties
    couponCode: (state) => {
      return state.products.moodifier.couponCode;
    },
    couponDiscount: (state) => {
      return state.products.moodifier.couponDiscount;
    },

    couponMaxDate: (state) => {
      return new Date(state.products.moodifier.couponMaxDate);
    },

    // Order properties
    order: (state) => {
      return state.order;
    },

    id: (state) => {
      return state.order.id;
    },
    status: (state) => {
      return state.order.status;
    },
    paidFor: (state) => {
      return state.order.paidFor;
    },
    quantity: (state) => {
      return state.order.quantity;
    },
    color: (state) => {
      return state.order.color;
    },

    firstName: (state) => {
      return state.order.firstName;
    },
    lastName: (state) => {
      return state.order.lastName;
    },
    email: (state) => {
      return state.order.email;
    },
    country: (state) => {
      if (state.order.country === '') {
        return 'Israel';
      }

      return state.order.country;
    },
    city: (state) => {
      return state.order.city;
    },
    address: (state) => {
      return state.order.address;
    },
    zipCode: (state) => {
      return state.order.zipCode;
    },

    totalAmount: (state) => {
      if (state.order.totalAmount === 0) {
        state.order.totalAmount = state.products.moodifier.price;
        return state.order.totalAmount;
      }
      return state.order.totalAmount;
    },

    orderCouponCode: (state) => {
      return state.order.orderCouponCode;
    },
    orderCouponDiscount: (state) => {
      return state.order.orderCouponDiscount;
    },
  },

  mutations: {
    setPrice(state, value) {
      state.products.moodifier.price = value;
    },

    setOriginalPrice(state, value) {
      state.products.moodifier.originalPrice = value;
    },

    setCurrency(state, value) {
      state.products.moodifier.currency = value;
    },

    setCurrencySign(state, value) {
      state.products.moodifier.currencySign = value;
    },

    setColors(state, value) {
      state.products.moodifier.colors = value;
    },
    // Coupon mutations
    setCouponCode(state, value) {
      state.products.moodifier.couponCode = value;
    },
    setCouponDiscount(state, value) {
      state.products.moodifier.couponDiscount = value;
    },
    setCouponMaxDate(state, value) {
      state.products.moodifier.couponMaxDate = value;
    },

    // Order mutations
    setOrderId(state, value) {
      state.order.id = value;
    },
    setOrderStatus(state, value) {
      state.order.status = value;
    },
    setOrderPaidFor(state, value) {
      state.order.paidFor = value;
    },
    setOrderQuantity(state, value) {
      state.order.quantity = value;
    },
    setOrderColor(state, value) {
      state.order.color = value;
    },
    setOrderTotalAmount(state, value) {
      state.order.totalAmount = value;
    },
    setOrderFirstName(state, value) {
      state.order.firstName = value;
    },
    setOrderLastName(state, value) {
      state.order.lastName = value;
    },
    setOrderEmail(state, value) {
      state.order.email = value;
    },
    setOrderCountry(state, value) {
      state.order.country = value;
    },
    setOrderCity(state, value) {
      state.order.city = value;
    },
    setOrderAddress(state, value) {
      state.order.address = value;
    },
    setOrderZipCode(state, value) {
      state.order.zipCode = value;
    },
    setOrderCouponCode(state, value) {
      state.order.orderCouponCode = value;
    },
    setOrderCouponDiscount(state, value) {
      state.order.orderCouponDiscount = value;
    },
  },

  actions: {
    setPrice({ commit }, value) {
      commit('setPrice', value);
    },
    setOriginalPrice({ commit }, value) {
      commit('setOriginalPrice', value);
    },
    setCurrency({ commit }, value) {
      commit('setCurrency', value);
    },
    setCurrencySign({ commit }, value) {
      commit('setCurrencySign', value);
    },
    setColors({ commit }, value) {
      commit('setColors', value);
    },
    // Coupon actions
    setCouponCode({ commit }, value) {
      commit('setCouponCode', value);
    },
    setCouponDiscount({ commit }, value) {
      commit('setCouponDiscount', value);
    },
    setCouponMaxDate({ commit }, value) {
      commit('setCouponMaxDate', value);
    },

    //order actions
    setOrderId({ commit }, value) {
      commit('setOrderId', value);
    },
    setOrderStatus({ commit }, value) {
      commit('setOrderStatus', value);
    },
    setOrderPaidFor({ commit }, value) {
      commit('setOrderPaidFor', value);
    },
    setOrderFirstName({ commit }, value) {
      commit('setOrderFirstName', value);
    },
    setOrderQuantity({ commit }, value) {
      commit('setOrderQuantity', value);
    },
    setOrderColor({ commit }, value) {
      commit('setOrderColor', value);
    },
    setOrderTotalAmount({ commit }, value) {
      commit('setOrderTotalAmount', value);
    },
    setOrderLastName({ commit }, value) {
      commit('setOrderLastName', value);
    },
    setOrderEmail({ commit }, value) {
      commit('setOrderEmail', value);
    },
    setOrderCountry({ commit }, value) {
      commit('setOrderCountry', value);
    },
    setOrderCity({ commit }, value) {
      commit('setOrderCity', value);
    },
    setOrderAddress({ commit }, value) {
      commit('setOrderAddress', value);
    },
    setOrderZipCode({ commit }, value) {
      commit('setOrderZipCode', value);
    },
    setOrderCouponCode({ commit }, value) {
      commit('setOrderCouponCode', value);
    },
    setOrderCouponDiscount({ commit }, value) {
      commit('setOrderCouponDiscount', value);
    },
  },
  modules: {},
});
