import { mapGetters, mapActions } from 'vuex';
import Coupon from '@/components/PaypalButton/Coupon.vue'
export default {
  name: 'PaypalButton',
  components: { Coupon },
  computed: {
    ...mapGetters([
      'price',
      'currencySign',
      'currency',
      'colors',
      'order',
      'quantity',
      'paidFor',
      'totalAmount',
      'selectedColor',
      'orderCouponDiscount',
    ]),
    couponField(value) {
      return value;
    },
    paidForFlag: {
      get() {
        return this.paidFor;
      },
      set(value) {
        this.setOrderPaidFor(value);
      },
    },
    colorField: {
      get() {
        console.log("colorField: ", this.order.color)
        if (this.order.color === '' || this.order.color === 'Grey') {
          return 'Gray';
        }
        return this.order.color;
      },
      set(value) {
        this.setOrderColor(value);
      },
    },
    quantityField: {
      get() {
        return this.quantity;
      },
      set(value) {
        this.setOrderQuantity(value);
        this.setOrderTotalAmount(
          (this.price - this.orderCouponDiscount) * this.quantityField
        );
      },
    },
  },
  data: function() {
    return {
      loaded: false,
      couponApplied: false,
      paypalLink: 'https://www.paypal.com/sdk/js',
      clientIdProd: process.env.VUE_APP_PAYPAL_PROD_CLIENT_ID,
      clientIdDev: process.env.VUE_APP_PAYPAL_SANDBOX_CLIENT_ID,
    };
  },
  mounted: function() {
    const script = document.createElement('script');
    if (process.env.NODE_ENV === 'production') {
      script.src = `${this.paypalLink}?currency=${this.currency}&client-id=${this.clientIdProd}`;
    } else {
      script.src = `${this.paypalLink}?currency=${this.currency}&client-id=${this.clientIdDev}`;
    }
    script.addEventListener('load', this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    ...mapActions([
      'setOrderPaidFor',
      'setOrderQuantity',
      'setOrderColor',
      'setOrderTotalAmount',
      'setOrderId',
      'setOrderStatus',
      'setOrderFirstName',
      'setOrderLastName',
      'setOrderEmail',
      'setOrderCity',
      'setOrderAddress',
      'setOrderZipCode',
    ]),

    couponFound(){
        this.couponApplied = true;
        this.setOrderTotalAmount(
          (this.price - this.orderCouponDiscount) * this.quantityField)       
    },
    setLoaded: function() {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.description,
                  amount: {
                    // currency_code: this.currency,
                    value: this.totalAmount,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            console.log("inside onApprove ", order)
            // this.data;
            this.paidForFlag = true;
            this.couponApplied = true;
            if (process.env.NODE_ENV !== 'production') {
              console.log('order approved: ', order);
              console.log(
                'order id: ',
                order.purchase_units[0].payments.captures[0].id
              );
            }
            this.setOrderId(order.purchase_units[0].payments.captures[0].id);
            this.setOrderStatus(order.status);
            // Populate form fields
            if (order.payer.email_address !== undefined) {
              this.setOrderEmail(order.payer.email_address);
            }

            if (order.payer.name.given_name !== undefined) {
              this.setOrderFirstName(order.payer.name.given_name);
            }
            if (order.payer.name.surname !== undefined) {
              this.setOrderLastName(order.payer.name.surname);
            }

            if (
              order.purchase_units[0].shipping.address.admin_area_2 !==
              undefined
            ) {
              this.setOrderCity(
                order.purchase_units[0].shipping.address.admin_area_2
              );
            }

            if (
              order.purchase_units[0].shipping.address.address_line_1 !==
              undefined
            ) {
              this.setOrderAddress(
                order.purchase_units[0].shipping.address.address_line_1
              );
            }

            if (
              order.purchase_units[0].shipping.address.postal_code !== undefined
            ) {
              this.setOrderZipCode(
                order.purchase_units[0].shipping.address.postal_code
              );
            }

            //
          },
          onError: (err) => {
            console.log("API ERROR: ", err.message);
          },
        })
        .render(this.$refs.paypal);
    },
  },
  populateOrderFields(order) {
    // console.log('order.payer.email_address');
    if (order.payer.email_address !== undefined) {
      this.setOrderEmail(order.payer.email_address);
    }

    if (order.payer.name.given_name !== undefined) {
      this.setOrderFirstName(order.payer.name.given_name);
    }
    if (order.payer.name.surname !== undefined) {
      this.setOrderLastName(order.payer.name.surname);
    }

    if (order.purchase_units[0].shipping.address.admin_area_2 !== undefined) {
      this.setOrderCity(order.purchase_units[0].shipping.address.admin_area_2);
    }

    if (order.purchase_units[0].shipping.address.address_line_1 !== undefined) {
      this.setOrderAddress(
        order.purchase_units[0].shipping.address.address_line_1
      );
    }

    if (order.purchase_units[0].shipping.address.postal_code !== undefined) {
      this.setOrderZipCode(
        order.purchase_units[0].shipping.address.postal_code
      );
    }
  },
};
