import { createApp } from 'vue'
import App from './App.vue'

import './registerServiceWorker'

import router from './router'
import store from './store'
// 
import OrderForm from '@/components/OrderForm/OrderForm.vue';
import Storyblok from './utils/Storyblok'
import landingPage from '@/components/LandingPage';
import product from '@/components/Product';
import productDetails from '@/components/productDetails';
import imageFeatures from '@/components/imageFeatures';
import generalInfo from '@/components/generalInfo';
import productFeatures from '@/components/productFeatures';
// 


import VueGtag from 'vue-gtag-next';

import './styles/app.css';


const app = createApp(App)
app
  .use(store)
  .use(router)
  .use(Storyblok)
  .use(VueGtag, {
    property: {
      id: 'UA-57147400-5',
    },
  })

app.component('order-form',OrderForm)
app.component('landingPage', landingPage);
app.component('product', product);
app.component('productDetails', productDetails);
app.component('imageFeatures', imageFeatures);
app.component('productFeatures', productFeatures);
app.component('generalInfo', generalInfo);
app.mount('#app');
