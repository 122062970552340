<template>
  <div>
    <div v-editable="blok">
        <p>{{blok.description}}</p>
        <p>{{blok.mail}}</p>
    </div>
  </div>
</template>

<script>
  export default {
   props: ['blok'],

  };
</script>

<style></style>
