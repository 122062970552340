<template>
  <div v-editable="blok">
    <div
      class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
      <div class="lg:grid lg:grid-cols-3 lg:gap-x-4 lg:items-start">
        <productDetails 
        class="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0"
        :blok="blok.body[0]"> 
        </productDetails>
        <imageFeatures :blok="blok.body[1]"> </imageFeatures>
        <order-form> </order-form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['blok'],
  };
</script>
