<template>
  <div class="main-page">
  <div>
    <component :blok="story.content" :is="story.content.component"></component>
  </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import StoryblokClient from 'storyblok-js-client';
let storyapi = new StoryblokClient({
    accessToken: process.env.VUE_APP_STORYBLOK_SPACE_KEY_PREVIEW,
  });

export default {
    created() {
      window.storyblok.init({
        accessToken: process.env.VUE_APP_STORYBLOK_SPACE_KEY_PREVIEW,
      });
      window.storyblok.on('change', () => {
        this.getStory('home', 'draft');
      });
      window.storyblok.pingEditor(() => {
        if (window.storyblok.isInEditor()) {
          this.getStory('home', 'draft');
        } else {
          this.getStory('home', 'published');
        }
      });
    },

    computed: {
      ...mapGetters([ 'order']),
      selectedColor() {
        return 'White'
      },
      colorField: {
        get() {
          if (this.order.color === '') {
            return 'Gray';
          }
          return this.order.color;
        },
        set(value) {
          this.setOrderColor(value);
        },
      },
    },
    methods: {
      ...mapActions(['setOrderColor']),
      selectedImage(color) {
        this.colorField = color;
      },
      getStory(slug, version) {
        storyapi
          .get('cdn/stories/' + slug, {
            version: version,
          })
          .then((response) => {
            // console.log();           
            this.story = response.data.story 
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    data(){
      return {
        moodifierDetails : {},
        story : {
          content : {
            body: []
          }
        }
      }
    }
  };
</script>

<style scoped>
.main-page {
  height: 110vh;
  background-color: rgba(146, 190, 235, .2);
      /* background-color: #737b83; */
      /* background-color: rgba(66, 219, 219,.8); */

}
</style>