<template>
  <div>
    <div v-editable="blok">
      <TabGroup as="div" class="flex flex-col-reverse">
        <!-- Image selector -->
        <div
          class="mt-6 sm:ml-5 md:ml-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none"
        >
          <TabList class="grid  grid-cols-4 gap-6">
            <Tab
              v-for="image in blok.images"
              :key="image._uid"
              class="relative h-24 bg-white rounded-md flex items-center 
              justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer 
              hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
              v-slot="{ selected }"
            >
              <span class="sr-only">
                {{ image.name }}
              </span>
              <span class="absolute inset-0 rounded-md overflow-hidden">
                <img
                  :src="image.image[0].filename"
                  :alt="image.image[0].alt"
                  @click="selectedImage(image.color)"
                  class="w-full h-full object-center object-cover"
                />
              </span>
              <span
                :class="[
                  selected ? 'ring-indigo-500' : 'ring-transparent',
                  'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none',
                ]"
                aria-hidden="true"
              />
            </Tab>
          </TabList>
        </div>
        <!-- Main Image -->
        <TabPanels class="w-full aspect-w-1 aspect-h-1 ">
          <TabPanel v-for="image in blok.images" :key="image.id">
              <!-- :src="image.image[0].filename" -->
            <img
              :src="image.image[0].filename + '/m/fit-in/600x600/filters:fill(f6fbff)'"
              :alt="image.image[0].alt"
              class=" w-full h-full	object-center object-cover  rounded-3xl
              "
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  </div>
</template>

<script>
  import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    props: ['blok'],
    components: {
      Tab,
      TabGroup,
      TabList,
      TabPanel,
      TabPanels,
    },
    computed: {
    ...mapGetters([
      'order'
    ]),
      colorField: {
        get() {
          if (this.order.color === '') {
            return 'Gray';
          }
          return this.order.color;
        },
        set(value) {
          this.setOrderColor(value);
        },
      },
    },
    methods: {
      ...mapActions(['setOrderColor']),
      selectedImage(color) {
        this.colorField = color;
      },
    },
  };
</script>

<style></style>
