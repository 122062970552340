<div>
  <paypal-button v-if="!paidForFlag"> </paypal-button>

  <div v-if="paidForFlag && uiState === 'form submitted'">
    <button
      @click.prevent="newOrder"
      class=" buttons bg-pink-600 hover:bg-pink-700 focus:ring-pink-500"
    >
      New Order
    </button>
  </div>
  <div v-if="paidForFlag" class="container text-2xl font-medium text-green-800">
    <p>
      Your payment status is <span class=""> {{order.status}}</span>.
      <br />
      The transaction Id is <span>{{order.id}}</span>
    </p>
    <div
      v-if="!v$.$invalid && v$.form.$errors.length === 0"
      class="grid grid-cols-1 text-2xl font-medium justify-start m-4  "
    >
      <div v-if="uiState !== 'form submitted'"
      class="flex justify-center items-center shadow-md bg-green-400 rounded-md sm:p-1 md:p-4">
        Total Amount: {{totalAmountField}} {{currency}}
      </div>
    </div>
    <p v-if="uiState !== 'form submitted'">{{uiMessage}}</p>
    <p v-else class="success text-md">
      Hooray! Your order was submitted!
      We just sent you a confirmation email with the order's details
    </p>          
  </div>
  <form ref="form"
    v-if="paidForFlag && uiState !== 'form submitted'"
    class=""
    @submit.prevent="submitForm"
    name="humidifierOrder"
    method="POST"
    netlify
    autocomplete="on"
  >
    <!-- FirstName -->
    <div class="field-class">
      <label
        for="first-name"
        class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        First name
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
          type="text"
          name="first-name"
          id="first-name"
          v-model.lazy="v$.form.firstName.$model"
          class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500
             sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          :class="{ error: v$.form.firstName.$errors.length }"
        />
      </div>
      <p v-if="errors" class="error">
        <span v-if="!v$.form.firstName.required">this field is required.</span>
        <span v-if="!v$.form.firstName.minLength"
          >Field must have at least {{ v$.form.firstName.$params.minLength.min
          }} characters.</span
        >
      </p>
    </div>
    <!-- LastName -->
    <div class="field-class">
      <label
        for="last-name"
        class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Last name
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
          type="text"
          name="last-name"
          id="last-name"
          v-model.lazy="v$.form.lastName.$model"
          class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 
            sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          :class="{ error: v$.form.lastName.$errors.length }"
        />
      </div>
      <p v-if="errors" class="error">
        <span v-if="!v$.form.lastName.required">this field is required.</span>
        <span v-if="!v$.form.lastName.minLength"
          >Field must have at least {{ v$.form.lastName.$params.minLength.min }}
          characters.</span
        >
      </p>
    </div>
    <!-- Email -->
    <div class="field-class">
      <label
        for="email"
        class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Email
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
          type="text"
          name="email"
          id="email"
          v-model.lazy="v$.form.email.$model"
          class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 
            sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          :class="{ error: v$.form.email.$errors.length }"
        />
      </div>
      <p v-if="errors" class="error">
        <span v-if="!v$.form.email.required">this field is required.</span>
        <span v-if="!v$.form.email.required">enter valid email address.</span>
      </p>
    </div>

    <!-- Color -->
    <!-- <p class="text-base mt-6 mr-8 flex justify-center ">Color</p> -->
    <div class="hidden">
      <div class="mt-1 sm:mt-0 sm:col-span-1">
        <input
          type="hidden"
          readonly
          id="color"
          name="color"
          v-model="form.color"
        />
      </div>
    </div>
    <!-- Quantity -->
    <div class="hidden">
      <div>
        <input
          readonly
          type="hidden"
          name="quantity"
          id="quantity"
          v-model="form.quantity"
        />
      </div>
    </div>
    <!-- Country  -->
    <div class="field-class">
      <label
        for="country"
        class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Country
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
          type="text"
          name="country"
          id="country"
          v-model.lazy="v$.form.country.$model"
          class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500
             sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          :class="{ error: v$.form.country.$errors.length }"
        />
      </div>
    </div>

    <!-- City  -->
    <div class="field-class">
      <label
        for="city"
        class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        City
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
          type="text"
          name="city"
          id="city"
          v-model.lazy="v$.form.city.$model"
          class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 
              sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          :class="{ error: v$.form.city.$errors.length }"
        />
      </div>
    </div>
    <!-- Address -->
    <div class="field-class">
      <label
        for="address"
        class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Address
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
          type="text"
          name="address"
          id="address"
          v-model.lazy="v$.form.address.$model"
          autocomplete="street-address"
          class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 
              sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          :class="{ error: v$.form.address.$errors.length }"
        />
      </div>
    </div>
    <!-- ZIP Code -->
    <div class="field-class">
      <label
        for="zip"
        class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        ZIP / Postal Code
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
          type="text"
          name="zip"
          id="zip"
          placeholder="ZIP / Postal Code"
          autocomplete="postal-code"
          v-model.lazy="v$.form.zipCode.$model"
          class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 
            sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          :class="{error: v$.form.zipCode.$errors.length }"
        />
      </div>
    </div>
    <!-- Order Id -->
    <div class="hidden">
      <div>
        <input
          readonly
          type="hidden"
          min="1"
          max="10"
          step="1"
          name="orderId"
          id="orderId"
          v-model="order.id"
        />
      </div>
    </div>
    <!-- Coupon Code -->
    <div class="hidden">
      <div>
        <input
          readonly
          type="hidden"
          name="couponCode"
          id="couponCode"
          v-model="order.orderCouponCode"
        />
      </div>
    </div>
    <!-- Coupon Discount -->
    <div class="hidden">
      <div>
        <input
          readonly
          type="hidden"
          name="couponDiscount"
          id="couponDiscount"
          v-model="order.orderCouponDiscount"
        />
      </div>
    </div>

    <!--  -->
    <!-- Submit Button -->
    <button
      type="submit"
      class="buttons bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
    >
      Send Details
    </button>

    <button
      @click.prevent="cleanForm"
      class=" buttons bg-pink-600 hover:bg-pink-700 focus:ring-pink-500"
    >
      Clear Form
    </button>

    <p v-if="errors.length" class="error">
      The form above has the following errors:
      <br />
      <span v-for="error in errors" :key="error" class="m-3"
        >{{ error.$property }}: {{ error.$message }}
        <br />
      </span>
    </p>

    <p v-else-if="empty && uiState === 'submit clicked'" class="error">
      The form above is empty! <br />
      Please fill your order
    </p>
    <p v-else-if="v$.$invalid && uiState === 'submit clicked'" class="error">
      Not all fields are filled! <br />
      Please fill your order
    </p>
  </form>
</div>
